import React from 'react'

import { Table } from 'antd'
import t from 'prop-types'

export default function TableComponent({
  size,
  data,
  total,
  current,
  loading,
  onClick,
  columns,
  pageSize,
  rowSelection,
  onChangePageSize,
  disableOptionChangeItemsPerPage,
}) {
  const extraOptions =
    disableOptionChangeItemsPerPage === true
      ? { defaultPageSize: 10, showSizeChanger: false }
      : {}

  console.log({ data })

  return (
    <Table
      dataSource={data}
      columns={columns}
      loading={loading}
      pagination={{
        ...extraOptions,
        total,
        current,
        pageSize,
      }}
      scroll={{ y: 480 }}
      size={size || 'middle'}
      style={{ marginTop: 5 }}
      onChange={onChangePageSize}
      onRow={(item, index) => ({
        onClick: () => (onClick ? onClick(item, index) : null),
      })}
      rowKey={(i, _) => i}
      rowSelection={rowSelection || null}
    />
  )
}

TableComponent.propTypes = {
  pageSize: t.number,
  size: t.string.isRequired,
  data: t.arrayOf([]).isRequired,
  total: t.number.isRequired,
  loading: t.bool.isRequired,
  onClick: t.func.isRequired,
  columns: t.arrayOf([]).isRequired,
  current: t.number.isRequired,
  onChange: t.func.isRequired,
  rowSelection: t.objectOf({}).isRequired,
  handlePagination: t.func.isRequired,
  disableOptionChangeItemsPerPage: t.bool,
  onChangePageSize: t.func,
}

import React, { useEffect } from 'react'

import { Button, Drawer } from 'antd'
import dayjs from 'dayjs'
import t from 'prop-types'

import Table from '~/components/organisms/Table'
import useRegisters from '~/hooks/useRegisters'

import {
  APPROVATION_BATCH_STATUS,
  INIT_PAGE,
  QUEUE_APPROVATION_STATUS,
} from '../helpers'
import { ReloadOutlined } from '@ant-design/icons'

const ITEMS_PER_PAGE = 10

export function ApprovationsBatch({ onClose, open }) {
  const {
    loadingRegister,
    getQueueApprovationsBatch,
    paginationAprove: pagination,
    queueApprovationsBatch,
  } = useRegisters()

  const status = [
    QUEUE_APPROVATION_STATUS.QUEUED,
    QUEUE_APPROVATION_STATUS.PROCESSING,
    QUEUE_APPROVATION_STATUS.DONE,
  ]
  const COLUMNS = [
    {
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
    },
    {
      title: 'Aprovados',
      key: 'total_processed',
      dataIndex: 'total_processed',
    },
    {
      title: 'Situação',
      key: 'status',
      dataIndex: 'status',
      render: (rowStatus) => APPROVATION_BATCH_STATUS[rowStatus],
    },
    {
      title: 'Criado em',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (createdAt) => dayjs(createdAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Atualizar em',
      key: 'updated_at',
      dataIndex: 'updated_at',
      render: (updatedAt) => dayjs(updatedAt).format('DD/MM/YYYY HH:mm'),
    },
  ]

  useEffect(() => {
    const sub = getQueueApprovationsBatch(status, INIT_PAGE, ITEMS_PER_PAGE)

    return () => sub
  }, []) //eslint-disable-line

  return (
    <Drawer
      title="Fila de Aprovações"
      placement="right"
      closable={false}
      onClose={onClose}
      width={720}
      open={open}
      key="approvation-batch"
    > 
      <Button 
        type='primary' 
        style={{ float: "right" }}
        onClick={() => getQueueApprovationsBatch(status, INIT_PAGE, ITEMS_PER_PAGE)}
      >
        <ReloadOutlined />
      </Button>
      <Table
        key="table"
        size="small"
        data={queueApprovationsBatch}
        columns={COLUMNS}
        total={pagination.total}
        loading={loadingRegister}
        current={pagination.current}
        pageSize={pagination.pageSize}
        disableOptionChangeItemsPerPage={true}
        onChange={(data) => {
          return getQueueApprovationsBatch(status, data.current, ITEMS_PER_PAGE)
        }}
        handlePagination={(page) => { }}
      />
    </Drawer>
  )
}

ApprovationsBatch.propTypes = {
  onClose: t.func,
  open: t.bool,
}
